/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import MyModal from '../../components/BookingEditPopUp'
import DeleteModal from '../../components/BookingDeletePopUp'
// Data
import bookingTableData from "layouts/tables/data/bookingTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDBadge from "components/MDBadge";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

function Tables() {

  const navigate = useNavigate();
  const location = useLocation();

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [id, setID] = useState('');
  const [delete_id, setDeleteID] = useState('');
  const [referralname, setReferralName] = useState('');
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [successSB, setSuccessSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);


  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);




  const [search, setSearch] = useState('');


  const short_date = date.split(" ");


  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={title}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );
  const openEditPopup = (data) => {

    if (!data.booking_id) {
      setID(data.id.props.children);
      setStatus(data.Status.props.children.props.badgeContent);
      setReferralName(data.Referral?.props?.children || " ");
      setDate(data.date ? data.date : "");
      setTime(data.time);
      setIsEditOpen(true);
    } else {

      setID(data.booking_id);
      setStatus(data.status);
      setReferralName(data.Referral ? data.Referral : "");
      setDate(data.date);
      setTime(data.time);
      setIsEditOpen(true);
    }
  }

  const openDeletePopUp = (data) => {
    ///console.log('deleeee',data);
    if (data.booking_id) {
      setDeleteID(data.booking_id.props.children);
      setIsDeleteOpen(true);
    } else {
      setDeleteID(data.id.props.children.props.children);
      setIsDeleteOpen(true);
    }

  }


  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);


  const closeEditPopup = () => {
    setIsEditOpen(false);

  }
  const closeDeletePopup = () => {
    setIsDeleteOpen(false);

  }



  const column = { Header: "Action", accessor: "action" };
  const { columns: tableColumns, rows: tableRows } = bookingTableData();
  //const row={edit: 'edit'}

  const update = (value) => {
    if (value.message) {
      setIsEditOpen(false);
      updateRowById(value.booking_id, value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    } else {
      setIsEditOpen(false);
      // updateRowById(value.booking_id,value);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }


  }

  const deleteupdate = (value) => {
    if (value.message) {
      setIsDeleteOpen(false);
      deleteRowById(value.id);
      setMessage(value.message);
      setTitle('Deleted');
      openSuccessSB();
    } else {
      setIsDeleteOpen(false);
      //deleteRowById(value.id);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }

  }

  const deleteRowById = (id) => {
    console.log('id,,', id);
    setRows(prevRows => prevRows.filter(row => row.id.props.children.props.children !== id));
  };
  useEffect(() => {

    if (rows.length > 0 || search != '') {
      return
    }
    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> 
            <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    setColumns([...tableColumns, column]);
    setRows(updatedRows);

  }, [tableColumns, rows]);


  const updateRowById = (id, updatedValues) => {
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children === id);
    if (rowIndex !== -1) {
      // Update the row data immutably

      const updatedRows = rows.map((row, index) =>
        index === rowIndex
          ? {
            ...row,

            Status:
              updatedValues.status === 'pending' ? (
                <MDBox ml={-1}>
                  <MDBadge badgeContent={updatedValues.status} variant="gradient" size="sm" />
                </MDBox>
              ) : updatedValues.status === 'cancelled' ? (
                <MDBox ml={-1}>
                  <MDBadge badgeContent={updatedValues.status} color="error" variant="gradient" size="sm" />
                </MDBox>
              )
                :updatedValues.status === 'requested' ? (
                  <MDBox ml={-1}>
                    <MDBadge badgeContent={updatedValues.status} color="warning" variant="gradient" size="sm" />
                  </MDBox>
                ): (
                  <MDBox ml={-1}>
                    <MDBadge badgeContent={updatedValues.status} color="success" variant="gradient" size="sm" />
                  </MDBox>
                ),

            Date: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.date + " AT " + updatedValues.time}</MDTypography>,

            action:
              <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
                <span onClick={() => openEditPopup(updatedValues)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(updatedValues)}> <DeleteIcon fontSize="medium" color="error" /> </span>
              </MDTypography>,

          }
          : row
      );

      setRows(updatedRows);
    }
  }

  const handleSearch = (query) => {

    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span> <span onClick={() => openDeletePopUp(row)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          </MDTypography>
      }

    ))

    console.log("updatedRows>", updatedRows)


    const searchTerm = query.toLowerCase();
    const filteredData = updatedRows.filter((row) => {
      //console.log(row.name.props.children.toLowerCase())
      const rowPatientValue = row.Patient.props.children.toLowerCase();
      const rowStatusValue = row.Status.props.children.props.badgeContent.toLowerCase();
      return rowPatientValue.includes(searchTerm)
        || rowStatusValue.includes(searchTerm);
    });

    console.log("filteredData>", filteredData)

    setRows(filteredData);


  };



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Bookings
                </MDTypography>
              </MDBox>

              <MDBox pt={3}>


                <MDBox width="12rem" ml="auto" mr="1em">
                  <MDInput
                    placeholder="filter by patient name, status"
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      handleSearch(currentTarget.value);
                    }}
                  />
                </MDBox>



                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={10}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <Footer />
      <MyModal isOpen={isEditOpen} onClose={closeEditPopup} id={id} status={status} time={time} date={short_date[0]} onUpdate={update} />
      <DeleteModal isdeleteOpen={isDeleteOpen} ondeleteClose={closeDeletePopup} delete_id={delete_id} ondeleteUpdate={deleteupdate} />
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Tables;
