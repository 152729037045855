/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography"
import MDBadge from "components/MDBadge";
import PopUp from "components/MDAlert";
import { useState, useEffect } from "react";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import AccessibilityIcon from '@mui/icons-material/Accessibility';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MDSnackbar from "components/MDSnackbar";


import MyModal from '../../components/CenterEditPopUp';
import DeleteModal from '../../components/CenterDeletePopUp';
import StatusModel from '../../components/CenterStatusPopUp';
import AddCenterModal from '../../components/CenterAddPopUp';
import Add from '../../components/AssignBodyPartsPopUp';
import AddAvailbaites from '../../components/AssignAvailabitiesPopUp';
import AddDiscount from '../../components/AddDiscountPopUp';

// Data
import centerTableData from "layouts/tables/data/centerTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";


import { useLocation, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Tooltip } from "@mui/material";

function Tables() {


  const navigate = useNavigate();
  const location = useLocation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const [isAddCenter, setIsAddCenterPopUp] = useState(false);
  const [isAddDiscount, setIsAddDiscountPopUp] = useState(false);
  const [isAddAvailbaites, setIsAddAvailbaites] = useState(false);
  const [address,setAddress]=useState('')
  const [scanprovider,setScanProvider]=useState('');
  const [isAdd, setIsAddPopUp] = useState(false);
  const [id, setID] = useState('');
  const [centerid, setCenterId] = useState('');
  const [delete_id, setDeleteID] = useState('');
  const [status_id, setStatusID] = useState('');
  const [newcenterId, setNewCenterId] = useState('');
  const [status, setStatus] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [successSB, setSuccessSB] = useState(false);



  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const [search, setSearch] = useState('');


  const [errorSB, setErrorSB] = useState(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);



  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={title}
      content={message}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );


  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={title}
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const openEditPopup = (data) => {

    if (!data.center_id) {
      setID(data.id.props.children);
      if (data.name == '') {
        setName('null');
      } else {
        setName(data.name.props.children);
      }
      setEmail(data.email);
      setPhoneNumber(data.phone.props.children);
      setAddress(data.address.props.children);
      setScanProvider(data.scanprovider.props.children);
      setIsEditOpen(true);
    } else {
      setID(data.center_id);
      setName(data.name);
      setEmail(data.email);
      setAddress(data.address);
      setScanProvider(data.scanprovider);
      setPhoneNumber(data.phonenumber);
      setIsEditOpen(true);
    }
  }

  const openDeletePopUp = (data) => {
    if (data.center_id) {
      setDeleteID(data.center_id);
      setIsDeleteOpen(true);
    } else {
      setDeleteID(data.id.props.children);
      setIsDeleteOpen(true);
    }

  }

  const openStatusPopUp = (data) => {
    if (data.center_id) {
      setStatusID(data.center_id);
      setStatus(data.status);
      setIsStatusOpen(true);
    } else {
      setStatusID(data.id.props.children);
      setStatus(data.status);
      setIsStatusOpen(true);
    }

  }

  const openAddCenterPopUp = () => {
    setIsAddCenterPopUp(true);
  }

  
  const openAddDiscountPopUp = () => {
    setIsAddDiscountPopUp(true);
  }

  const openAddPopUp = (data) => {
    setCenterId(data.id.props.children);
    setIsAddPopUp(true);
  }

   const openAvailabilityPopup = (data) => {
    console.log('dataaaa',data);
    if(data.center_id){
    setIsAddAvailbaites(true);
    setNewCenterId(data.center_id)
    }else{
        setIsAddAvailbaites(true);
    setNewCenterId(data.id.props.children)
    }
  
  }



  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);


  const closeEditPopup = () => {
    setIsEditOpen(false);

  }

  const closeDeletePopup = () => {
    setIsDeleteOpen(false);
  }
  const closeStatusPopup = () => {
    setIsStatusOpen(false);
  }
  const closeAddCenterPopup = () => {
    setIsAddCenterPopUp(false);
  }
  const closeAddPopup = () => {
    setIsAddPopUp(false);
  }
  const closeAddAvailbaites = () => {
    setIsAddAvailbaites(false);
  }
  const closeAddDiscount = () => {
    setIsAddDiscountPopUp(false);
  }



  const column = { Header: "Action", accessor: "action" };
  const column2 = { Header: "Status", accessor: "status" };
  const { columns: tableColumns, rows: tableRows } = centerTableData();
  //const row={edit: 'edit'}

  const update = (value) => {
    if (value.message) {
      setIsEditOpen(false);
      updateRowById(value.center_id, value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    } else {
      setIsEditOpen(false);
      // updateRowById(value.center_id,value);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }


  }

  const deleteUpdate = (value) => {
    if (value.message) {
      setIsDeleteOpen(false);
      deleteRowById(value.id);
      setMessage(value.message);
      setTitle('Deleted');
      openSuccessSB();
    } else {
      setIsDeleteOpen(false);
      //deleteRowById(value.id);
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }


  }

  const statusUpdate = (value) => {
    if (value.message) {
      setIsStatusOpen(false);
      updateRowStatusById(value);
      setMessage(value.message);
      setTitle('Updated');
      openSuccessSB();
    } else {
      setIsStatusOpen(false);
      ///updateRowStatusById(value); 
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }

  }
  const addedCenter = (value) => {
    setIsAddCenterPopUp(false);
    if (value.message) {
      updateData(value.center_id, value);
      setMessage(value.message);
      setTitle('Added');
      openSuccessSB();
      if (value.checked == false) {
        setIsAddAvailbaites(true);
        setNewCenterId(value.center_id)
      }
    } else if (value.error) {
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }
  }

  const addbodypatsUpdate = (value) => {
    setIsAddPopUp(false);
    if (value.message) {
      setMessage(value.message);
      setTitle('Update');
      openSuccessSB();

    } else if (value.error) {
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }
  }

  const AddAvailbaitesupdate = (value) => {
    setIsAddAvailbaites(false);
    if (value.message) {
      setMessage(value.message);
      setTitle('Added');
      openSuccessSB();
    } else if (value.error) {
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }
  }
  const addedbodyparts = (value) => {
    // setIsAddCenterPopUp(false); 
    // if(value.message){
    //   updateData(value.center_id,value);
    //   setMessage(value.message);
    //   setTitle('Added');
    //   openSuccessSB();
    //  }else if(value.error){
    //   setMessage(value.error);
    //   setTitle('Error');
    //   openErrorSB();
    //  }
  }


  const AddDiscountupdate = (value) => {
    setIsAddDiscountPopUp(false);
    if (value.message) {
      setMessage(value.message);
      setTitle('Added');
      openSuccessSB();
    } else if (value.error) {
      setMessage(value.error);
      setTitle('Error');
      openErrorSB();
    }
  }

  const deleteRowById = (id) => {
    setRows(prevRows => prevRows.filter(row => row.id.props.children !== id));
  };
  useEffect(() => {

    if (rows.length > 0 || search != '') {
      return
    }
    const updatedRows = tableRows.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium">
            <span onClick={() => openEditPopup(row)}>
            <Tooltip title="click to edit center">
            <EditIcon fontSize="medium" color="secondary" /> 
            </Tooltip></span>
            <span onClick={() => openDeletePopUp(row)}>
            <Tooltip title="click to delete center">
            <DeleteIcon fontSize="medium" color="error" />
            </Tooltip> </span>
            <span onClick={() => openAddPopUp(row)}> 
            <Tooltip title="click to set scan types & bodyparts"><AccessibilityIcon fontSize="medium" color="secondary" />
                </Tooltip>  </span>
            <span onClick={() => openAvailabilityPopup(row)}>
            <Tooltip title="click to set availabilities">
            <EditCalendarIcon  fontSize="medium" color="secondary" />
            </Tooltip>
            </span>
            

          </MDTypography>,
        status:
          <MDTypography component="a" href="#" variant="caption" color={row.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(row)}>
            {row.status == true ? <MDBox ml={-1}>
              <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
            </MDBox> : <MDBox ml={-1}>
              <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
            </MDBox>}
          </MDTypography>,
        add:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" onClick={() => openAddPopUp(row)}>
            <MDBox ml={-1}>
              <MDBadge badgeContent='ADD' color="success" variant="gradient" size="sm" />
            </MDBox>
          </MDTypography>,
      }

    ))

    setColumns([...tableColumns, column, column2]);
    setRows(updatedRows);

  }, [tableColumns]);


  const updateRowById = (id, updatedValues) => {
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children === id);
    if (rowIndex !== -1) {
      // Update the row data immutably

      const updatedRows = rows.map((row, index) =>
        index === rowIndex
          ? {
            ...row,
            phone: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.phonenumber}</MDTypography>,
            name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.name}</MDTypography>,
            address: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.address}</MDTypography>,
            scanprovider: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{updatedValues.scanprovider}</MDTypography>,
            action:
              <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
                <span onClick={() => openEditPopup(updatedValues)}><EditIcon fontSize="medium" color="secondary" />
                </span> <span onClick={() => openDeletePopUp(updatedValues)}> 
                  
                  <Tooltip title="click to delete center">
            <DeleteIcon fontSize="medium" color="error" /> 
            </Tooltip> </span>
                <span onClick={() => openAddPopUp(updatedValues)}>
                  <Tooltip title="click to set scan types & bodyparts"><AccessibilityIcon fontSize="medium" color="secondary" />
                  </Tooltip>
                </span>
                <span onClick={() => openAvailabilityPopup(updatedValues)}>
                <Tooltip title="click to set availabilities">
                <EditCalendarIcon  fontSize="medium" color="secondary" />
                </Tooltip>
                </span>
              </MDTypography>,
              

          }
          : row
      );

      setRows(updatedRows);
    }
  }
  const updateRowStatusById = (value) => {
    // Find the index of the row with the specific id
    const rowIndex = rows.findIndex(row => row.id.props.children === value.id);
    if (rowIndex !== -1) {
      // Update the row data immutably

      const updatedRows = rows.map((row, index) =>

        index === rowIndex
          ? {
            ...row,
            status:
              <MDTypography component="a" href="#" variant="caption" fontWeight="medium" onClick={() => openStatusPopUp({ ...row, status: value.status })}>
                {value.status == true ? (<MDBox ml={-1}>
                  <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
                </MDBox>) : (<MDBox ml={-1}>
                  <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
                </MDBox>)}
              </MDTypography>

          }
          : row
      );

      setRows(updatedRows);
    }
  }

  const updateData = (id, values) => {

    const newRow = {
      id: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{id}</MDTypography>,
      name: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.name}</MDTypography>,
      address:<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{}</MDTypography>,
      phone: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.phonenumber}</MDTypography>,
      post_code: <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">{values.address}</MDTypography>,

      action:
        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
          <span onClick={() => openEditPopup(values)}><EditIcon fontSize="medium" color="secondary" /> </span>
          <span onClick={() => openDeletePopUp(values)}> <DeleteIcon fontSize="medium" color="error" /> </span>
          <span onClick={() => openAddPopUp(values)}>
         
            <Tooltip title="click to set scan types & bodyparts"><AccessibilityIcon fontSize="medium" color="secondary" />
            </Tooltip>  </span>
            <span onClick={() => openAvailabilityPopup(values)}>
                <Tooltip title="click to set availabilities">
                <EditCalendarIcon  fontSize="medium" color="secondary" />
                </Tooltip>
                </span>
        </MDTypography>,
      status:
        <MDTypography component="a" href="#" variant="caption" color={values.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(values)}>
          {values.status == true ? <MDBox ml={-1}>
            <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
          </MDBox> : <MDBox ml={-1}>
            <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
          </MDBox>}
        </MDTypography>,
      add:
        <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" onClick={() => openAddPopUp(row)}>
          <MDBox ml={-1}>
            <MDBadge badgeContent='ADD' color="success" variant="gradient" size="sm" />
          </MDBox>
        </MDTypography>,
    }

    setRows([...rows, newRow]);

  }

  const handleSearch = (query) => {


    const searchTerm = query.toLowerCase();
    const filteredData = tableRows.filter((row) => {
      const rowStatusValue = row.status ? 'active' : 'deactive';
      const rowValue = row.name.props.children.toLowerCase();
      const rowEmailValue = row.email.props.children.toLowerCase();
      return rowValue.includes(searchTerm) ||
        rowStatusValue == searchTerm ||
        rowEmailValue.includes(searchTerm)
    });

    const updatedRows = filteredData.map((row) => (

      {
        ...row, action:
          <MDTypography component="a" href="#" variant="caption" color="success" fontWeight="medium" >
            <span onClick={() => openEditPopup(row)}><EditIcon fontSize="medium" color="secondary" /> </span>
            <span onClick={() => openDeletePopUp(row)}> 
            <Tooltip title="click to delete center">
            <DeleteIcon fontSize="medium" color="error" /> 
            </Tooltip></span>
            <span onClick={() => openAddPopUp(row)}>
              <Tooltip title="click to set scan types & bodyparts">
                <AccessibilityIcon fontSize="medium" color="secondary" />
              </Tooltip>  </span>
          </MDTypography>,
        status:
          <MDTypography component="a" href="#" variant="caption" color={row.status == true ? 'success' : 'error'} fontWeight="medium" onClick={() => openStatusPopUp(row)}>
            {row.status == true ? <MDBox ml={-1}>
              <MDBadge badgeContent='Active' color="success" variant="gradient" size="sm" />
            </MDBox> : <MDBox ml={-1}>
              <MDBadge badgeContent='Deactive' color="error" variant="gradient" size="sm" />
            </MDBox>}
          </MDTypography>
      }

    ))
    setRows(updatedRows);


  };




  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  All Centers
                </MDTypography>

              </MDBox>


              <MDBox pt={3}>
                <MDBox width="12rem" ml="auto" mr="1em">
                <Tooltip title="search by center name, email and status">
                  <MDInput
                    placeholder="filter by name, email, status"
                    value={search}
                    size="small"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      handleSearch(currentTarget.value);
                    }}
                  />
                  </Tooltip>
                </MDBox>

                

                

                <MDBox display="flex" justifyContent="flex-end" mt="2rem" mr="1em">
                <MDBox width="8rem">
                  <MDButton variant="gradient" color="info" onClick={openAddCenterPopUp}>Add Center</MDButton>
                </MDBox>
                
                <MDBox width="11rem">
                  <MDButton variant="gradient" color="info" onClick={openAddDiscountPopUp}>Manage Discount</MDButton>
                </MDBox>
              </MDBox>
                <MDBox width="12rem" ml="auto" mr="1em">

                </MDBox>


                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={10}
                  showTotalEntries={true}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>

        </Grid>
      </MDBox>
      <MyModal isOpen={isEditOpen} onClose={closeEditPopup} id={id} name={name} email={email} phone_number={phone_number} address={address} scanprovider={scanprovider} onUpdate={update} />
      <DeleteModal isdeleteOpen={isDeleteOpen} ondeleteClose={closeDeletePopup} delete_id={delete_id} ondeleteUpdate={deleteUpdate} />
      <StatusModel isstatusOpen={isStatusOpen} onstatusClose={closeStatusPopup} status_id={status_id} status={status} onstatusUpdate={statusUpdate} />

      <AddCenterModal isOpen={isAddCenter} onClose={closeAddCenterPopup} onAdd={addedCenter} />
      <Add isOpen={isAdd} onClose={closeAddPopup} onAdd={addedbodyparts} centerId={centerid} onUpdate={addbodypatsUpdate} />

      <AddAvailbaites isOpen={isAddAvailbaites} onClose={closeAddAvailbaites} centerId={newcenterId} onUpdate={AddAvailbaitesupdate} />
      <AddDiscount isOpen={isAddDiscount} onClose={closeAddDiscount} onUpdate={AddDiscountupdate} />
      <Footer />
      {renderSuccessSB}
      {renderErrorSB}
    </DashboardLayout>

  );
}

export default Tables;
